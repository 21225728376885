<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-05-17 12:08:30
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-09-15 09:44:33
 * @FilePath: \dalian\src\components\TabContent2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab-content2">
    <img src="../assets/banner2.png" class="banner">
    <div class="content">
      <div class="tab-box">
        <div class="active">行业前沿</div>
        <div>政策法规</div>
        <div>产业洞察</div>
        <div>公司动态</div>
      </div>
      <div class="tab-content">
        <div class="tab-content-item">
          <img src="../assets/tab3/1.png">
          <div class="left">
            <div class="title">"绿色金融数字化：区块链技术助力实现‘双碳’目标"</div>
            <div class="desc">在全球推动绿色经济的大背景下，区块链技术正成为绿色金融领域的重要推手。最新案例揭示，通过区块链实现的碳足迹追踪和碳信用交易，极大地提高了透明度和效率，为企业和个人提供了参与碳中和的创新途径。这标志着数字化技术在促进环境保护和可持续发展方面迈出了关键一步。</div>
            <!-- <div class="desc">发布日期 : 2023-05-15</div> -->
          </div>
        </div>
        <div class="tab-content-item">
          <img src="../assets/tab3/2.png">
          <div class="left">
            <div class="title">"普惠金融新篇章：大数据风控开启小微企业信贷新时代"</div>
            <div class="desc">利用大数据和人工智能的强大力量，普惠金融进入了一个新的发展阶段。最新的大数据风控模型能够高效评估小微企业的信用状况，降低放贷风险，使得更多小微企业能够获得急需的资金支持。这项技术的广泛应用，正逐步消除融资难、融资贵的问题，推动经济的包容性增长。</div>
            <!-- <div class="desc" style="margin-top:20px">发布日期 : 2023-04-26</div> -->
          </div>
        </div>
        <div class="tab-content-item">
          <img src="../assets/tab3/3.png">
          <div class="left">
            <div class="title">"金融科技新星：AI驱动的智能投顾平台引领个性化理财新...</div>
            <div class="desc">随着人工智能技术的飞速发展，智能投顾平台正以前所未有的个性化服务重塑个人理财领域。最新报告显示，采用先进算法的智能投顾不仅能根据投资者的风险偏好和财务目标定制投资组合，还能实时调整策略，最大化收益潜力。这一变革让普通投资者也能享受到过去仅面向高端客户的定制化服务。</div>
            <!-- <div class="desc">发布日期 : 2023-04-21</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabContent3'
}
</script>

<style scoped>
.banner{
  height: 651px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 56px auto;
 }

 .tab-box{
  display: flex;
  gap: 50px;
 }
  .tab-box>.active{
    background: #4068C7;
  }
   .tab-box>div{
    flex: 1;
    height: 63px;
    background: #474747;
    border-radius: 10px;
    font-size: 24px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tab-content-item{
    display: flex;
    gap: 26px;
    margin-top: 90px;
  }
  .tab-content-item>img{
    width: 536px;
    height: 227px;
  }
  .tab-content-item>.left>.title{
    font-size: 24px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #111111;
    margin-bottom: 15px;
  }
  .tab-content-item>.left>.desc{
    font-size: 20px;
    font-family: Alibaba PuHuiTi;
    font-weight: 300;
    color: #111111;
    line-height: 30px;
    text-align: justify;
  }
</style>
