<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-05-17 12:08:30
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-09-15 08:55:25
 * @FilePath: \dalian\src\components\TabContent2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab-content2">
    <img src="../assets/banner3.png" class="banner">
    <div class="content">
      <GridTitle text="企业简介"></GridTitle>
      <div class="grid-1">
        <img src="../assets/tab4/1.png">
        <div class="sub-title">企业介绍</div>
        <div>
          湖南商祺信息技术有限公司，成立于2021年12月，注册资本5000万元。公司是一家迅速崛起的高新技术企业，专注于为金融行业提供创新与高效的信息技术服务解决方案。
          公司植根于信息技术咨询服务的核心领域，依托强大的技术实力与深厚的行业理解，致力于打造全方位的信息系统集成服务与定制化软件开发，特别是在银行代收通道服务及技术对接方面，
          已成为业界瞩目的佼佼者。
        </div>
        <div>
          在数字经济蓬勃发展的背景下，湖南商祺信息技术有限公司精准定位市场，特别是在消费金融、小额贷款、基金等金融细分领域，我们以客户需求为导向，专业提供安全、稳定、
          高效的银行代收通道服务。这一服务不仅极大地简化了客户支付流程，提高了资金流转效率，还通过先进的加密技术和严格的合规管理，确保每一笔交易的安全性与合规性，
          赢得了广大客户的信赖与支持。公司除了为客户提供数字化技术服务平台，还为客户提供银行通道科技服务。目前公司与国内多家银行开展合作，支持通道多，接口种类全，可为消费金融公司、
          基金公司、小额贷款公司提供最合适的支付通道，提高交易成功率，有效节省客户自主对接时间。
        </div>
        <div>
          我们的技术团队由一群经验丰富、充满激情的专业人士组成，他们精通于金融科技的最前沿技术，能够快速响应市场变化，为消金、小贷、基金行业的各类机构量身定制技术对接方案。
          从需求分析、系统设计到实施部署，每一个环节都力求精益求精，确保无缝接入各大银行系统，实现数据的即时交互与处理，有效提升合作伙伴的业务运营效率和用户体验。
          湖南商祺信息技术有限公司秉承“技术创新、合作共赢”的经营理念，持续探索金融科技领域的无限可能。
          我们不仅是技术服务商，更是客户成长道路上的坚实伙伴，致力于通过我们的专业服务，帮助客户在激烈的市场竞争中抢占先机，实现共赢发展。
          展望未来，湖南商祺信息技术有限公司将继续深耕金融科技领域，不断拓展服务范围，优化产品结构，以更加开放的姿态拥抱变革，引领行业创新潮流，
          为推动中国金融科技的进步与发展贡献我们的力量。
        </div>
        <div class="sub-title">企业介绍</div>
        <div>
          开展助贷业务中，资金方给借款人授信通过后给借款人放款，融担为借款合同提供担保服务，反担保主体提供反担保服务，贷后管理主体提供贷后管理服务，借款人发生逾期行为后，
          融担为借款人进行本息代偿，融担通过**银行直连代收服务收取借款人还款，同名转至将该笔借款的本息清分给反担保主体，将技术服务费清分给技术服务方，将贷后管理费清分给贷后管理方。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GridTitle from './GridTitle.vue'
export default {
  name: 'TabContent4',
  components:{
    GridTitle
  }
}
</script>

<style scoped>
.banner{
  height: 651px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 0 auto;
 }

 .grid-1{
    font-size: 22px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #666666;
    line-height: 32px;
    padding: 67px 0 57px 0;
    text-align: justify;
 }
 .grid-1>div{
    padding: 19px 0;
 }
 .grid-1>img{
    height: 555px;
    width: 1015px;
    margin: 0 auto 40px auto;
    display: block;
 }

 .sub-title{
  font-size: 22px;
  padding: 0 !important;
  font-weight: bold;
 }
</style>
