<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-05-17 12:08:30
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-09-15 09:05:03
 * @FilePath: \dalian\src\components\TabContent2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab-content2">
    <img src="../assets/banner1.png" class="banner">
    <div class="content">
      <GridTitle text="我们的服务"></GridTitle>
      <div class="grid-1">
        <img src="../assets/tab2/1.png">
        <img src="../assets/tab2/2.png">
        <img src="../assets/tab2/3.png">
        <img src="../assets/tab2/4.png">
      </div>
    </div>
  </div>
</template>

<script>
import GridTitle from './GridTitle.vue'
export default {
  name: 'TabContent2',
  components:{
    GridTitle
  }
}
</script>

<style scoped>
.banner{
  height: 651px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 0 auto;
 }

 .grid-1{
    /* 设置容器布局为grid布局 */
    display: grid;
    grid-row-gap:95px;
    /* 指定每一列的宽度 每个宽度中间用空格隔开 */
    grid-template-columns: 1fr;
      /* 指定每一行的宽度 每个宽度中间用空格隔开 */
    /* grid-template-rows:285px; */
    /* 单元格顺序,可以实现避免空格 */
    padding: 67px 0 57px 0;
 }
 .grid-1>img{
    height: 408px;
    max-width: 1200px;
    margin: 0 auto;
    object-fit: cover;
 }
</style>
